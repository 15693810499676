<template>
  <div id="base" class="select-none w-screen h-screen bg-gray-900 flex flex-col gap-16 place-items-center place-content-center">
    <img src="@/assets/v-logo.svg" alt="Lumina RP Logo" class="w-1/2 h-1/2">
    <h1 class="text-5xl text-gray-300 font-europa-regular">COMING SOON</h1>
  </div>
</template>

<script>

export default {
  name: 'App',
  created(){
    document.title = "Lumina RP | Coming soon"
  }
}
</script>

<style>
@import url("https://use.typekit.net/xit3cqn.css");

.font-ardoise-regular{
  font-family: ardoise-tight-std,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-europa-regular{
  font-family: europa,sans-serif;
  font-weight: 400;
  font-style: normal;
}
</style>
